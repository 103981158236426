define("Utils/QueryString.js", function() {
    var qs = function () {
        var qs = location.search.slice(1).split("&");
        var qsPart;
        var o = {};
        for (var i = qs.length - 1; i >= 0; i--) {
            qsPart = qs[i].split("=");
            if (o[qsPart[0]]) {
                if ($.isArray(o[qsPart[0]])) {
                    o[qsPart[0]].push(qsPart[1] || "");
                } else {
                    o[qsPart[0]] = [o[qsPart[0]], qsPart[1] || ""];
                }
            } else {
                o[qsPart[0]] = qsPart[1] || "";
            }
        }
        return o;
    };

    return {
        getValue: function(key) {
            return qs()[key];
        }
    };
});
