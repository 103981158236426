define("Utils/Cookies.js", ["$"], function($) {

    var splitStringToObject = function (content, groupSeparator, keyValueSeparator) {
        var obj = {};

        if (content) {
            var groups = content.split(groupSeparator);
            for (var i = 0; i < groups.length; i++) {
                var sepIndex = groups[i].indexOf(keyValueSeparator);
                obj[$.trim(groups[i].substr(0, sepIndex))] = groups[i].substr(sepIndex + 1);
            }
        }
        return obj;
    };
    var joinObjectToString = function (obj, groupSeparator, keyValueSeparator) {
        var output = [];
        for (var key in obj) {
            output.push(key + keyValueSeparator + obj[key]);
        }
        return output.join(groupSeparator);
    };
    var readCookie = function (cookieName) {
        var cookiesObj = splitStringToObject(document.cookie, ";", "=");
        var value = cookiesObj[cookieName] || "";
        return unescape(value);
    };
    var getCookieObject = function (cookieName) {
        var cookieData = readCookie(cookieName);
        return splitStringToObject(cookieData, "&", "=");
    };
    var cookieExists = function (cookieName) {
        if (document.cookie.indexOf(cookieName) >= 0) {
            return true;
        }
        return false;
    };
    var clearCookie = function (cookieName) {
        var expiryDate = new Date();
        expiryDate.setFullYear(1900, 1, 1);
        document.cookie = cookieName + '=; expires=' + expiryDate.toUTCString() + '; path=/';
    };

    var setCookie = function (cookieName, value, lifespanInDays) {
        var expires = "";
        if (lifespanInDays) {
            var date = new Date();
            date.setTime(date.getTime() + lifespanInDays * 24 * 60 * 60 * 1000); // ) removed
            expires = "; expires=" + date.toGMTString(); // + added
        }
        document.cookie = cookieName + "=" + value + expires + ";path=/"; // + and " added
    };

    return {
        readCookie: readCookie,

        cookieExists: cookieExists,

        clearCookie: clearCookie,

        readFromCookie: function (cookieName, key) {
            var cookieObj = getCookieObject(cookieName);
            return cookieObj[key] || "";
        },
        writeToCookie: function (cookieName, key, value, lifespanInDays) {
            var cookieObj = getCookieObject(cookieName);
            cookieObj[key] = escape(value);
            var cookieValue = joinObjectToString(cookieObj, "&", "=");
            setCookie(cookieName, cookieValue, lifespanInDays);
        },
        writeCookie: function (cookieName, value, lifespanInDays) {
            setCookie(cookieName, escape(value), lifespanInDays);
        }
    };

});
